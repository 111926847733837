<template>
    <div v-if="loaded" class="fill-height">
        <app-top-menu :drawer="drawer" @nav-toggle="navToggle"></app-top-menu>
        <app-sidebar :drawer="drawer" @nav-toggle="navToggle"></app-sidebar>
        <v-main>
            <transition name="fade" mode="out-in">
                <router-view :key="componentKey"></router-view>
            </transition>
        </v-main>
        <app-footer></app-footer>
    </div>
</template>
<script>

import Vue from 'vue'
import mixin from '../../plugins/mixin'

Vue.mixin(mixin)

import Echo from "laravel-echo";
import {getMessaging, getToken} from "firebase/messaging";
import {mapGetters, mapActions} from "vuex"
import AppSidebar from "../../components/AppSidebar"
import AppTopMenu from "../../components/AppTopMenu"
import AppFooter from "../../components/AppFooter"

export default {
    name: "MainLayout",
    components: {
        AppSidebar,
        AppTopMenu,
        AppFooter
    },
    props: {
        source: String,
        push: Object
    },
    provide: function () {
        return {
            forceRerender: this.forceRerender,
            setUserData: this.setUserData,
        }
    },
    data() {
        return {
            loaded: false,
            drawer: false,
            componentKey: 0,
            friend: null,
        }
    },
    computed: {
        ...mapGetters(['lang', 'language', 'defaultAvatar', 'userAvatar', 'userName', 'userLogin', 'userInitials', 'theme']),
    },
    async mounted() {
        this.appName = process.env.VUE_APP_NAME
        this.drawer = this.$vuetify.breakpoint.mdAndUp
        await this.setTheme()
        await this.$auth
            .load()
            .then(() => {
                if (this.$auth.check() && this.$auth.user() && this.$auth.user().settings) {
                    this.setSettings(this.$auth.user().settings)
                    this.setUserName(this.$auth.user().name)
                    this.setUserInitials(this.$auth.user().name)
                    this.setUserPosition(this.$auth.user().position)
                    this.setUserAvatar((this.$auth.user().photo ? this.$auth.user().photo : this.defaultAvatar))
                    this.listenChannelWebsocket()
                    this.listenChannelFCM()
                }
                this.loaded = true
            })
        this.setLang()
    },
    methods: {
        ...mapActions(['setSettings', 'setLanguage', 'setUserName', 'setUserInitials', 'setUserPosition', 'setUserAvatar']),
        setLang() {
            if (this.$auth.check() && this.$auth.user() && this.$auth.user().language) {
                document.documentElement.lang = this.$auth.user().language
                this.setLanguage(this.$auth.user().language)
            }
            if (typeof this.$i18n.locale !== 'undefined') {
                this.$i18n.locale = document.documentElement.lang
            }
            this.$moment.locale(this.$i18n.locale)
            if (typeof this.$vuetify.lang.current !== 'undefined') {
                this.$vuetify.lang.current = document.documentElement.lang
            }
        },
        setTheme(themeSelect) {
            let localTheme = localStorage.getItem('themeDark')
            localTheme = localTheme === 'true' ? true : (localTheme === 'false' ? false : null)
            const mediaTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
            this.$vuetify.theme.dark = (themeSelect !== undefined ? themeSelect : (localTheme !== null ? localTheme : mediaTheme))
            localStorage.setItem('themeDark', this.$vuetify.theme.dark)
            document.documentElement.setAttribute('class', (this.$vuetify.theme.dark ? 'dark' : 'light'))
        },
        forceRerender: function () {
            this.componentKey += 1
        },
        navToggle() {
            this.drawer = !this.drawer
        },
        checkNotification() {
            var _this = this
            if (!("Notification" in window)) {
                console.log("This browser does not support desktop notification");
            } else if (Notification.permission === "granted") {
                console.log("Notification.permission granted");
                // If it's okay let's create a notification
                //var notification = new Notification("Hi there 1!")
                this.listenChannelFCM()
            } else if (Notification.permission !== 'denied' || Notification.permission === "default") {
                Notification.requestPermission(function (permission) {
                    console.log("User accepts, let's create a notification");
                    // If the user accepts, let's create a notification
                    if (permission === "granted") {
                        //var notification = new Notification("Hi there 2!")
                        _this.listenChannelFCM()
                    }
                })
            }
        },
        listenChannelFCM() {
            const messaging = getMessaging();
            getToken(messaging, {vapidKey: process.env.VUE_APP_FCM_VAPID_KEY})
                .then((token) => {
                    if (token) {
                        // Send the token to your server and update the UI if necessary
                        //console.log('Send the token to your server ', token);
                        console.log('Send the token to your server');
                        this.saveNotificationToken(token)
                    } else {
                        console.log('No registration token available. Request permission to generate one.');
                    }
                })
                .catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                });
        },
        saveNotificationToken(token) {
            const params = {
                token: token,
                type: 'browser'
            }
            this.$http
                .post("admin/device", params)
                .then((res) => {
                    console.log('Successfully saved notification token!')
                })
                .catch((err) => {
                    console.log('Error: could not save notification token')
                })
        },
        listenChannelWebsocket() {
            window.Echo = new Echo({
                broadcaster: "pusher",
                key: process.env.VUE_APP_PUSHER_APP_KEY,
                cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
                disableStats: true,
                encrypted: true,
                enabledTransports: ["wss", "ws"],
                //wsHost: window.location.hostname,
                wsHost: this.$pusherServer,
                authorizer: (channel, options) => {
                    return {
                        authorize: (socketId, callback) => {
                            this.$http.post(`https://${this.$pusherServer}/api/broadcasting/auth`, {
                                socket_id: socketId,
                                channel_name: channel.name
                            })
                                .then(response => {
                                    callback(false, response.data)
                                })
                                .catch(error => {
                                    callback(true, error)
                                })
                        }
                    }
                }
            })

            window.Echo
                .private(`App.Models.Admin.${this.$auth.user().id}`)
                .listen('.Event.Admin', (event) => {
                    console.log(event)
                    console.log('MainLayout Event.Admin')
                    if (event.type.indexOf('notify') !== -1) {
                        this.$toastr.success(`${event.title} ${event.message}`)
                    }
                })
            /*
            .notification((notify) => {
                //console.log('MainLayout notify')
                console.log(notify)
                if (notify.type.indexOf('broadcast.message') !== -1) {
                    this.$toastr.success(`${notify.title} ${notify.message}`)
                }
            })
           */
        },
        setUserData() {
            this.setSettings(this.$auth.user().settings)
            this.setUserName(this.$auth.user().name)
            this.setUserInitials(this.$auth.user().name)
            this.setLanguage(this.$auth.user().language)
            this.setUserAvatar(this.$auth.user().avatar)
            this.checkNotification()
            this.listenChannelWebsocket()
        },
    }
}
</script>
