<template>
    <v-navigation-drawer v-model="drawerShow" mobile-breakpoint="960" height="100%" app>
        <v-list class="py-0 company-logo" color="primary" style="border-radius:0;height:64px;">
            <v-list-item>
                <v-list-item-content>
                    <v-img src="/img/logo.png" max-height="40px" contain></v-img>
                </v-list-item-content>
                <v-list-item-icon v-if="$vuetify.breakpoint.smAndDown">
                    <v-btn small icon @click.stop="navToggle" class="mx-0">
                        <v-icon class="white--text">mdi-close</v-icon>
                    </v-btn>
                </v-list-item-icon>
            </v-list-item>
        </v-list>
        <v-divider class="my-0"></v-divider>
        <scrollbar ref="scrollbar" :swicher="!$vuetify.breakpoint.smAndDown"
                   :settings="settingsScrollbar" class="inner-scrollbar">
            <v-list class="py-0" dense v-for="(group, index) in items" :key="index">
                <template v-for="item in group">
                    <v-subheader v-if="item.header" v-show="item.visible">{{ $t(item.title) }}</v-subheader>
                    <v-list-group v-else-if="!!item.items" v-show="item.visible" :prepend-icon="item.icon" no-action
                                  :key="item.title" v-model="item.active">
                        <template v-slot:activator>
                            <v-list-item-content class="nav-bar-content">
                                <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-list-item v-for="subItem in item.items" :key="subItem.title" v-show="subItem.visible"
                                     @click.native="subItem.action ? subItem.action() : false" :to="subItem.to" ripple
                                     :exact="subItem.exact !== undefined ? subItem.exact : true">
                            <v-list-item-icon>
                                <v-icon>{{ subItem.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="pl-2 nav-bar-content">
                                <v-list-item-title>{{ $t(subItem.title) }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                    <v-list-item v-else-if="!item.header" v-show="item.visible"
                                 @click.native="item.action ? item.action() : false"
                                 href="javascript:void(0)" :to="item.to" ripple
                                 :exact="item.exact !== undefined ? item.exact : true" :key="item.title">
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="nav-bar-content">
                            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        </scrollbar>
    </v-navigation-drawer>
</template>
<script>

import Scrollbar from './Scrollbar'

export default {
    name: "AppSidebar",
    components: {
        Scrollbar,
    },
    inject: ['forceRerender'],
    props: {
        drawer: Boolean
    },
    data() {
        return {
            isMini: null,
            items: [],
            settingsScrollbar: {
                suppressScrollX: true,
            },
        }
    },
    computed: {
        drawerShow: {
            get: function () {
                return this.drawer
            },
            set: function (state) {
                if (state !== this.drawer) {
                    this.navToggle()
                }
            }
        },
        routePath() {
            return this.$route.path
        },
    },
    watch: {
        routePath(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.navigation()
            }
        },
    },
    mounted() {
        this.navigation()
    },
    methods: {
        navToggle() {
            this.$emit("nav-toggle")
        },
        navigation() {
            this.items = [
                [
                    {
                        title: 'menu_general',
                        header: true,
                        visible: this.$auth.check(),
                    },
                    {
                        title: 'menu_dashboard',
                        icon: "mdi-view-dashboard mdi-18px",
                        to: {name: "dashboard"},
                        action: this.forceRerender,
                        visible: this.$auth.check(),
                        exact: false
                    },

                    {
                        title: 'menu_administration',
                        header: true,
                        visible: this.can(['administrator','manager','accountant']),
                    },
                    {
                        title: 'menu_users',
                        icon: 'mdi-account-group mdi-18px',
                        visible: this.can(['administrator','manager','accountant']),
                        exact: false,
                        active: ['/administrator', '/client', 'invite'].arrayStartsWith(this.routePath),
                        items: [
                            {
                                title: 'menu_administrators',
                                icon: "mdi-account-tie mdi-18px",
                                to: {name: 'administrator'},
                                action: this.forceRerender,
                                visible: this.can(['administrator']),
                                exact: false
                            },
                            {
                                title: 'menu_partners',
                                icon: "mdi-account mdi-18px",
                                to: {name: 'partner'},
                                action: this.forceRerender,
                                visible: this.can(['administrator','manager']),
                                exact: false
                            }
                        ]
                    },
                    {
                        title: 'menu_about',
                        icon: "mdi-information mdi-18px",
                        to: {name: "about"},
                        action: this.forceRerender,
                        visible: this.can(['administrator', 'manager']),
                        exact: false
                    },
                    {
                        title: 'menu_pages',
                        icon: "mdi-page-layout-header-footer mdi-18px",
                        to: {name: "page"},
                        action: this.forceRerender,
                        visible: this.can(['administrator', 'manager']),
                        exact: false
                    },

                    {
                        title: 'menu_handbooks',
                        header: true,
                        visible: this.can(['administrator']),
                    },
                    {
                        title: 'menu_damages',
                        icon: "mdi-check mdi-18px",
                        to: {name: "damage"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_container_terminals',
                        icon: "mdi-check mdi-18px",
                        to: {name: "container_terminal"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_container_type_receipts',
                        icon: "mdi-check mdi-18px",
                        to: {name: "container_type_receipt"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_container_statuses',
                        icon: "mdi-check mdi-18px",
                        to: {name: "container_status"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_container_types',
                        icon: "mdi-check mdi-18px",
                        to: {name: "container_type"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_sexes',
                        icon: "mdi-check mdi-18px",
                        to: {name: "sex"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    }


                ]
            ]
        }
    }
}
</script>
