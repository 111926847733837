import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

function loadView(view) {
    return () => import(`../views/${view}.vue`);
}

const routes = [
    {
        path: "/",
        redirect: "/dashboard"
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        meta: {layout: 'main', auth: true},
        component: loadView('Dashboard')
    },

    {
        path: '/profile',
        name: 'profile',
        meta: {layout: 'main', auth: true},
        component: loadView('AdministratorProfile')
    },
    {
        path: '/administrator',
        name: 'administrator',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('Administrators')
    },
    {
        path: '/administrator/create',
        name: 'administrator.create',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('AdministratorProfile')
    },
    {
        path: '/administrator/:id/profile',
        name: 'administrator.profile',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('AdministratorProfile')
    },
    {
        path: '/partner',
        name: 'partner',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('Partners')
    },
    {
        path: '/partner/create',
        name: 'partner.create',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('PartnerForm')
    },
    {
        path: '/partner/:id/edit',
        name: 'partner.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('PartnerForm')
    },
   {
       path: '/company',
       name: 'company',
       meta: {layout: 'main', auth: {roles: ['administrator', 'manager','accountant']}},
       component: loadView('Companies')
   },
   {
       path: '/company/create',
       name: 'company.create',
       meta: {layout: 'main', auth: {roles: ['administrator', 'manager','accountant']}},
       component: loadView('CompanyProfile')
   },
   {
       path: '/company/:id/profile',
       name: 'company.profile',
       meta: {layout: 'main', auth: {roles: ['administrator', 'manager','accountant']}},
       component: loadView('CompanyProfile')
   },
    {
        path: '/topic',
        name: 'topic',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager','accountant']}},
        component: loadView('Topics')
    },
    {
        path: '/topic/create',
        name: 'topic.create',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager','accountant']}},
        component: loadView('TopicForm')
    },
    {
        path: '/topic/:id/edit',
        name: 'topic.edit',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager','accountant']}},
        component: loadView('TopicForm')
    },
    {
        path: '/sex',
        name: 'sex',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('Sexes')
    },
    {
        path: '/sex/create',
        name: 'sex.create',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('SexForm')
    },
    {
        path: '/sex/:id/edit',
        name: 'sex.edit',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('SexForm')
    },

    {
        path: '/damage',
        name: 'damage',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('Damages')
    },
    {
        path: '/damage/create',
        name: 'damage.create',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('DamageForm')
    },
    {
        path: '/damage/:id/edit',
        name: 'damage.edit',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('DamageForm')
    },
    {
        path: '/container_terminal',
        name: 'container_terminal',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('ContainerTerminals')
    },
    {
        path: '/container_terminal/create',
        name: 'container_terminal.create',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('ContainerTerminalForm')
    },
    {
        path: '/container_terminal/:id/edit',
        name: 'container_terminal.edit',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('ContainerTerminalForm')
    },
    {
        path: '/container_type_receipt',
        name: 'container_type_receipt',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('ContainerTypeReceipts')
    },
    {
        path: '/container_type_receipt/create',
        name: 'container_type_receipt.create',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('ContainerTypeReceiptForm')
    },
    {
        path: '/container_type_receipt/:id/edit',
        name: 'container_type_receipt.edit',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('ContainerTypeReceiptForm')
    },
    {
        path: '/container_status',
        name: 'container_status',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('ContainerStatuses')
    },
    {
        path: '/container_status/create',
        name: 'container_status.create',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('ContainerStatusForm')
    },
    {
        path: '/container_status/:id/edit',
        name: 'container_status.edit',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('ContainerStatusForm')
    },
    {
        path: '/container_type',
        name: 'container_type',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('ContainerTypes')
    },
    {
        path: '/container_type/create',
        name: 'container_type.create',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('ContainerTypeForm')
    },
    {
        path: '/container_type/:id/edit',
        name: 'container_type.edit',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('ContainerTypeForm')
    },

    {
        path: '/form_property',
        name: 'form_property',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('FormProperties')
    },
    {
        path: '/form_property/create',
        name: 'form_property.create',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('FormPropertyForm')
    },
    {
        path: '/form_property/:id/edit',
        name: 'form_property.edit',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: loadView('FormPropertyForm')
    },
    {
        path: '/invite',
        name: 'invite',
        meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
        component: loadView('Invites')
    },
   {
       path: '/page',
       name: 'page',
       meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
       component: loadView('Pages')
   },
   {
       path: '/page/create',
       name: 'page.create',
       meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
       component: loadView('PageForm')
   },
   {
       path: '/page/:id/edit',
       name: 'page.edit',
       meta: {layout: 'main', auth: {roles: ['administrator', 'manager']}},
       component: loadView('PageForm')
   },
    {
        path: '/about',
        name: 'about',
        meta: {layout: 'main', auth: { roles: ['administrator', 'manager'] }},
        component: loadView('About')
    },
    {
        path: '/auth/login',
        name: 'login',
        meta: { layout: 'single-page', auth: false },
        component: loadView('Auth/Login')
    },

    {
        path: '/auth/register',
        name: 'register',
        meta: {layout: 'single-page', auth: false},
        component: loadView('Auth/Login')
    },
    {
        path: '/auth/forgot',
        name: 'forgot',
        meta: {layout: 'single-page', auth: false},
        component: loadView('Auth/Forgot')
    },
    {
        path: '/auth/forgot/check',
        name: 'reset_password',
        meta: {layout: 'single-page', auth: false},
        component: loadView('Auth/ResetPassword')
    },
    {
        path: '/404',
        name: 'error-404',
        meta: {layout: 'main', auth: true},
        component: loadView('Errors/NotFound')
    },
    {
        path: '/403',
        name: 'error-403',
        meta: {layout: 'main', auth: true},
        component: loadView('Errors/Forbidden')
    },
    {
        path: '/block',
        name: 'block',
        meta: {layout: 'single-page', auth: undefined},
        component: loadView('Errors/Blocked')
    },
    {
        path: '/maintenance',
        name: 'maintenance',
        meta: {layout: 'single-page', auth: undefined},
        component: loadView('Errors/Maintenance')
    },
    {
        path: '*',
        redirect: '/404',
        meta: {layout: 'default', auth: undefined},
    },
];

Vue.router = new VueRouter({
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
        return window.scrollTo({top: 0, behavior: 'smooth'});
    },
    /*
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0
        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }
        return window.goTo(scrollTo)
    },
    */
    /*
    scrollBehavior() {
        return window.scrollTo({top: 0, behavior: 'smooth'});
    },
    */
    hashbang: false,
    linkActiveClass: 'active',
    transitionOnLoad: true,
    mode: 'history',
    base: __dirname,
    routes: routes
});

export default Vue.router;
