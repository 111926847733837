import { initializeApp } from "firebase/app"
import { getMessaging, isSupported } from "firebase/messaging"
//import { onBackgroundMessage } from 'firebase/messaging/sw'
//import { getAnalytics } from "firebase/analytics"

const firebaseConfig = {
    apiKey: "AIzaSyAPDYc7Jp7foXEySH1fx_BtMbhGLPlN0a0",
    authDomain: "atasumarket-16a5d.firebaseapp.com",
    projectId: "atasumarket-16a5d",
    storageBucket: "atasumarket-16a5d.appspot.com",
    messagingSenderId: "472188912049",
    appId: "1:472188912049:web:d849c771947dfd9a76fd90",
    measurementId: "G-FN8FNCSN4M"
};

const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app)
//const analytics = getAnalytics(app)

/*
if (isSupported()) {
    // Register Worker
} else {
    console.log("Browser doesn't support FCM")
}
*/
